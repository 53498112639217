import { createWebHistory, createRouter } from "vue-router";

const routes =  [
  {
    path: "/",
    alias: ["/qrcode"],
    name: "FELLPELLER",
    component: () => import("./components/Fellpeller")
  },
  {
    path: "/saturnalia/giveaway",
    alias: ["/saturnalia", "/giveaway"],
    name: "saturnaliagiveaway",
    component: () => import("./components/saturnalia/SaturnaliaGiveaway")
  },
  {
    path: "/work",
    name: "work",
    component: () => import("./components/work")
  },
  {
    path: "/about",
    name: "about",
    component: () => import("./components/about")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("./components/contact")
  },
  {
    path: "/merch",
    alias: ["/merchdrop"],
    beforeEnter() {
      location.href = "https://docs.google.com/forms/d/e/1FAIpQLSeLGXAZmV3v13zdDBKFf8kpDOsL3VSXJL7bikCwyIr_janVuQ/viewform?usp=sf_link";
    }
  },
  {
    path: "/madeforthis",
    alias: ["/madeforthistickets", "/tickets"],
    beforeEnter() {
      location.href = "https://www.eventbrite.com/e/made-for-this-movie-premiere-tickets-362285665177";
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
});

export default router;