import { ref, computed } from 'vue'

export const collapsed = ref(false)
export const toggleNav = () => (collapsed.value = !collapsed.value)
export const openNav = () => (collapsed.value = true)
export const closeNav = () => (collapsed.value = false)

/*let screen_width = window.innerWidth
let screen_height = window.innerHeight
let mobile = screen_width < screen_height*/

export const nav_width = 25
export const nav_width_closed = 5
export let navWidth = computed(() => `${collapsed.value ? 'navOpen' : 'navClosed'}`)
export let navIcon = computed(() => `${collapsed.value ? 'xmark' : 'bars'}`)
    
/*if(mobile) {
    navWidth = computed(() => `${collapsed.value ? '0' : '100'}vw`)
  } else {
    navWidth = computed(() => `${collapsed.value ? nav_width_closed : nav_width}vw`)
  }*/
