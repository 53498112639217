<template>
  <div id="app">
    <navbar />
    <transition name="fade">
    <div class="cookie" v-if="!TrustCookies"><p class="cookietext">By continuing on this webpage, you agree to the use of cookies. <button class="fill" @click="Cookie()">Accept</button></p></div>
     </transition>
    <router-view v-slot="{ Component }">
    <transition name="slide" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  </div>
</template>

<script>
import NavBar from "./components/navigation/navbar.vue";
import { useCookies } from "vue3-cookies";

export default {
  name: "app",
  data() {
    return {
      TrustCookies: false,
    };
  },
  components: {
    navbar: NavBar,
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  methods: {
    Cookie() {
      this.cookies.set("TrustCookies", true);
      this.TrustCookies = this.cookies.get("TrustCookies");
    },
  },
  mounted() {
    this.TrustCookies = this.cookies.get("TrustCookies");
  },
};
</script>

<style>
html, body {
  overflow: hidden;
  font-family: monospace;
  background-color: rgb(255, 255, 255);
}
.cookie {
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0;
  z-index: 5;
}
.cookietext {
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.8);
  font-size: large;
  text-align: center;
}
.fill {
  padding: 7px 25px 7px;
  outline: 0;
  border: 1px solid rgb(255, 255, 255);
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
}

.fill::after {
  content: "";
  background-color: #ffffff;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.fill:hover::after {
  top: 0px;
  left: 0px;
}
@keyframes fade-in {
  from {opacity: 0;}
  to {opacity: 100;}
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}


.slide-leave-active,
.slide-enter-active {
  transition: all 0.6s cubic-bezier(.11,.61,.51,.98);
  overflow: hidden;
}

.slide-leave-to {
  transform: translateY(-100vh);
  opacity: 0;
}
.slide-enter-from {
  transform: translateY(100vh);
  opacity: 0;
}
</style>
