<template>
  <transition name="fade">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200 200">
<defs>
	<path d="M 100, 100
        m -75, 0
        a 75,75 0 1,0 150,0
        a 75,75 0 1,0 -150,0" id="textcircle">
	</path>
</defs>
	<text dy="20" textLength="470">
    <!-- textLength (essentially the circumference of the circle) is used as an alternative to letter-spacing for Firefox, which currently doesn't support letter-spacing for SVG -->
		<textPath textLength="470" xlink:href="#textcircle">{{ $route.name }}•{{ $route.name }}•{{ $route.name }}•</textPath>
	</text>
</svg> 
  </div>
  </transition>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  name: "spin",
  data() {
    return {};
  },
  setup() {
    const route = useRoute();
    return route;
  },
};
</script>

<style scoped>
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
svg {
  width: 20vw;
  max-width: 40vh;
  animation: rotating 15s linear infinite;
  fill: black;
  filter: blur(0.3vw);
}
text {
  font-family: monospace;
  font-size: 45px;
  font-weight: 600;
}
::selection { background: transparent; }

@media (max-width: 100vh) {
  svg {
    width: 25vh;
    filter: blur(0.4vh);
  }
}
</style>