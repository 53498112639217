<template>
  <div class="navbar" :class="navWidth">
        <transition name="spin"><spin class="spin" :class="{ spinOpen: collapsed }" @click="toggleNav" v-if="$route.name !='FELLPELLER'" /></transition>
        <transition-group name="button">
            <font-awesome-icon v-if="collapsed" class="icon" icon="xmark" size="4x" @click="closeNav" />
            <font-awesome-icon v-if="!collapsed && $route.name == 'FELLPELLER'" class="icon" icon="bars" size="4x" @click="openNav" />
        </transition-group>
        <transition name="spin">
            <div class="navlinks" @click="closeNav" v-if="collapsed">
                <div class="spacer"></div>
                <router-link class="navbutton" to="/about">about</router-link>
                <router-link class="navbutton" to="/work">work</router-link>
                <router-link class="navbutton" to="/contact">contact</router-link>
            </div>
        </transition>
        <div v-if="collapsed" class="closeNav" @click="closeNav"></div>
        <div class="fillcolor"></div>
  </div>
</template>

<script>
import Spin from "./spin.vue"
import { collapsed, toggleNav, openNav, closeNav, navWidth } from './toggle'
import { useRoute } from 'vue-router'

export default {
    name: "navbar",
    data() {
        return { 
            collapsed,
            toggleNav,
            openNav,
            closeNav,
            navWidth,
        }
    },
    components: {
        spin: Spin,
    },
    setup() {
        const route = useRoute()
        return route
    }
}
</script>

<style scoped>
.navlinks {
    padding-bottom: 10vw;
    position: relative;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-rows: 30vw auto;
    justify-items: center;
    align-items: center;
    z-index: 5;
    overflow-y: scroll;
}
.spin {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 0;
    transition: margin-top 500ms cubic-bezier(0.040, 0.590, 0.195, 0.995);
    z-index: 10;
}
.spinblock {
    position: relative;
    opacity: 0;
    align-self: flex-start;
}
.spinOpen {
    margin-top: 5vw;
}
.closeNav {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    z-index: -1;
}
.navbar {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0);
    z-index: 5;
    transition: all 500ms cubic-bezier(0.040, 0.590, 0.195, 0.995);
    padding: 0;
    display: inline-block;
    
}
.fillcolor {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: white;
    z-index: -2;
    filter: blur(0.75vw);
    box-shadow: 0 -50vh 0 white, 0 50vh 0 #fff, 1vw 0 0 #fff;
}
.icon {
    position: fixed;
    top: 0;
    right: 0;
    height: 3.5vw;
    min-height: 10px;
    width: 3.5vw;
    padding: 0.75vw;
    color: white;
    color: black;
    filter: invert(1) blur(0.2vw);
    mix-blend-mode: difference;
    z-index: 10;
}
.navOpen {
    width: 25vw;
}
.navClosed {
    width: 5vw;
}
.button-enter-active {
    transition: all 0.25s ease-out;
}
.button-leave-active {
  transition: all 0.25s ease-in;
}

.button-enter-from {
    transform: rotate(-90deg);
    opacity: 0;
}
.button-leave-to {
  transform: rotate(90deg);
  opacity: 0;
}

.spin-enter-active, .spin-leave-active {
    transition: all 0.5s ease-in-out;
}

.spin-enter-from, .spin-leave-to {
    opacity: 0;
}
.navbutton {
    font-weight: bold;
    font-size: x-large;
    padding: 1vw;
    align-self: center;
    transition: all 1s ease;
}
.router-link-active {
    filter: blur(0.4vw);
    color: purple;
}
@media (max-width: 100vh) {
    .navlinks {
        grid-template-rows: 45vh auto;
        padding-bottom: 15vh;
    }
    .router-link-active {
    filter: blur(0.4vh);
}
    .spinOpen {
        margin-top: 10vh;
        
    }
    .navOpen {
    width: 100vw;
    }
    .navClosed {
        width: 1vh;
    }
    .icon {
    height: 6vh;
    min-height: 10px;
    width: 6vh;
    padding: 2vh;
    filter: invert(1) blur(0.3vh);
    }
}

</style>